<template>
  <b-card>
    <div
      v-if="isLoadingEmployeeDetails"
      class="d-flex justify-content-center mb-1"
      style="padding-top: 30vh"
    >
      <b-spinner
        variant="info"
        style="width: 3rem; height: 3rem;"
        class="mr-1"
      />
    </div>
    <b-tabs
      v-else
      pills
    >
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Informations personnelles</span>
        </template>
        <edit-employee-infos-tab
          :employee-data="employeeData"
          class="mt-2 pt-75"
        />
      </b-tab>

    </b-tabs>
  </b-card>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,

} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import EditEmployeeInfosTab from './EditEmployeeInfosTab.vue'
import EditEmployeeServicesTab from './EditEmployeeServicesTab.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    EditEmployeeInfosTab,
    EditEmployeeServicesTab,
  },
  data() {
    return {
      employeeData: {},
      isLoadingEmployeeDetails: false,
    }
  },
  computed: {
    ...mapGetters('professionals', ['getEmployeeDetails']),
  },
  created() {
    if (this.getEmployeeDetails == null) {
      this.applyGetEmployeeDetailsAction()
    } else {
      this.employeeData = this.getEmployeeDetails
    }
  },

  methods: {
    ...mapActions('professionals', [
      'getEmployeesDetailsAction',
    ]),
    applyGetEmployeeDetailsAction() {
      this.isLoadingEmployeeDetails = true
      this.getEmployeesDetailsAction(this.$route.params.id).then(response => {
        this.isLoadingEmployeeDetails = false
        this.employeeData = response.data
      }).catch(error => {
        this.isLoadingEmployeeDetails = false
        console.log(error)
      })
    },

  },
}

</script>

<style>

</style>
