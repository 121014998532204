<template>
  <div>
    <b-card>
      <div v-if="isLoadingEmployeeDetails" class="d-flex justify-content-center mb-1" style="padding-top: 30vh">
        <b-spinner variant="info" style="width: 3rem; height: 3rem;" class="mr-1" />
      </div>

      <validation-observer v-else ref="infoRules" tag="form">
        <b-row v-for="(service, index) in forms" :key="index">
          <b-col md="6">
            <validation-provider #default="{ errors }" name="recurring_services" rules="required">
              <b-form-group label="Service offert" label-for="recurring_services"
                :state="errors.length > 0 ? false : null">
                <v-select id="role" v-model="service.recurring_service_id" :reduce="(service) => service.id" label="name"
                  :options="recurringServicesOptions" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="photos">
              <b-form-group label="photos" label-for="photos" :state="errors.length > 0 ? false : null">
                <b-form-file v-model="service.pictures" placeholder="Choissez les réalisations des pros" multiple />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group label="Prétention salariale" label-for="salary_pretention">
              <validation-provider #default="{ errors }" name="pretention salariale" rules="required">
                <b-form-input id="salary_pretention" v-model="service.salary_pretention" type="number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <validation-provider #default="{ errors }" name="expériences" rules="required">
              <b-form-group label="Expériences" label-for="expériences" :state="errors.length > 0 ? false : null">
                <v-select id="years_of_experience" v-model="service.years_of_experience" :reduce="(range) => range.value"
                  label="value" :options="experiencesRange" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6" class="mb-2">
            <validation-provider #default="{ errors }" name="about" rules="required">
              <b-form-group label="Compétence" label-for="about" :state="errors.length > 0 ? false : null">
                <b-form-textarea id="about" v-model="service.about" placeholder="Textarea" rows="3" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>


        </b-row>
        <b-row class="mt-4 mb-4">

          <b-col>
            <b-button variant="primary" :disabled="isEmployeeUpdateLoading" @click="applyUpdateEmployeeAction">
              {{ isEmployeeUpdateLoading ? "Chargement..." : "Enregistrer" }}
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BSpinner,
  BFormInvalidFeedback,
  // BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  // BFormRadioGroup,
  BFormFile,
  // BFormCheckboxGroup,
  BButton,
  BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required, integer, beninPhoneNumberFormatRule } from '@validations'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    BFormInvalidFeedback,
    // BForm,
    BFormGroup,
    BFormFile,
    BFormTextarea,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BCard
  },
  /*  props: {
    employeeData: {
      type: Object,
      required: true,
    },
  }, */
  data() {
    return {
      forms: [],
      recurringServicesOptions: [],
      isEmployeeServicesLoading: true,
      isEmployeeUpdateLoading: false,
      payload: {
        about: "",
        pictures: [],
        years_of_experience: "",
        salary_pretention: "",
      },
      employeeData: null,
      required,
      isLoadingEmployeeDetails: false,
      experiencesRange: [
        {
          value: '0 à 1an',
        },
        {
          value: '1 à 3 ans',
        },
        {
          value: '3 à 5 ans',
        },
        {
          value: '5 à 10 ans',
        },
        {
          value: 'Plus de 10 ans',
        },
      ],
    }
  },
  watch: {
    getRecurringServicesWithoutPagination(newVal, oldVal) {
      newVal.forEach(element => {
        this.recurringServicesOptions.push({
          id: element.id,
          name: element.name,
        })
        this.isEmployeeServicesLoading = false
      })
    },
  },
  computed: {
    ...mapGetters('services', ['getRecurringServices', 'getRecurringServicesWithoutPagination']),
    ...mapGetters('professionals', ['getEmployeeDetails']),

  },
  mounted() {
    if (this.getRecurringServicesWithoutPagination.length != 0) {
      this.getRecurringServicesWithoutPagination.forEach(element => {
        this.recurringServicesOptions.push({
          id: element.id,
          name: element.name,
        })
      })
      this.isEmployeeServicesLoading = false
    }

    /* this.employeeData.recurring_services.forEach(el => {
      this.forms.push({
        recurring_service_id: el.pivot.recurring_service_id,
        about: el.pivot.about,
        pictures: [],
        years_of_experience: el.pivot.years_of_experience,
        salary_pretention: el.pivot.salary_pretention
      })
      console.log('El',this.forms)
    }) */
  },
  created() {
    if (this.getEmployeeDetails == null) {
      this.isLoadingEmployeeDetails = true
      this.getEmployeesDetailsAction(this.$route.query.employeeId).then(response => {
        this.isLoadingEmployeeDetails = false
        this.employeeData = response.data
        console.log(this.employeeData)
        const filteredService = this.employeeData.recurring_services.find(service => service.pivot.recurring_service_id === parseInt(this.$route.params.id))

        if (filteredService) {
          this.forms.push({
            recurring_service_id: filteredService.pivot.recurring_service_id,
            about: filteredService.pivot.about,
            pictures: [],
            years_of_experience: filteredService.pivot.years_of_experience,
            salary_pretention: filteredService.pivot.salary_pretention,
          })
        }
        console.log(this.employeeData)
      }).catch(error => {
        this.isLoadingEmployeeDetails = false
        console.log(error)
      })
    } else {
      this.employeeData = this.getEmployeeDetails
      const filteredService = this.employeeData.recurring_services.find(service => service.pivot.recurring_service_id === parseInt(this.$route.params.id))

      if (filteredService) {
        this.forms.push({
          recurring_service_id: filteredService.pivot.recurring_service_id,
          about: filteredService.pivot.about,
          pictures: [],
          years_of_experience: filteredService.pivot.years_of_experience,
          salary_pretention: filteredService.pivot.salary_pretention,
        })
      }
    }
  },
  methods: {
    ...mapActions('professionals', [
      'updateEmployeeServiceAction',
      'getEmployeesAction',
      'getEmployeesDetailsAction',
    ]),

    applyGetEmployeeDetailsAction() {
      this.isLoadingEmployeeDetails = true
      this.getEmployeesDetailsAction(this.$route.query.employeeId).then(response => {
        this.isLoadingEmployeeDetails = false
        this.employeeData = response.data
        console.log(this.employeeData)
      }).catch(error => {
        this.isLoadingEmployeeDetails = false
        console.log(error)
      })
    },
    applyUpdateEmployeeAction() {
      this.$refs.infoRules.validate().then(success => {
        if (success) {
          this.isEmployeeUpdateLoading = true
          this.forms.forEach(element => {
            this.payload.about = element.about
            this.payload.pictures = element.pictures
            this.payload.years_of_experience = element.years_of_experience
            this.payload.salary_pretention = element.salary_pretention

          })
          this.updateEmployeeServiceAction({
            payload: this.payload,
            employeeId: this.$route.query.employeeId,
            employeeRecurringService: this.$route.params.id,
          })
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.getEmployeesAction().then(() => {
                this.isEmployeeUpdateLoading = false
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: "Le(s) service(s) de l'employé a(ont) été modifié(e)s avec succès",
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
                this.payload = {
                  recurring_service_id: "",
                  about: "",
                  pictures: [],
                  years_of_experience: "",
                  salary_pretention: "",
                }
              })

              this.$store.commit('professionals/SET_EMPLOYEE_DETAILS', null)
              this.$router.push(`/employees/${this.employeeData.id}/details`)
            })
            .catch(error => {
              this.isEmployeeUpdateLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.payload = {
                recurring_service_id: [],
                about: [],
                pictures: [],
                years_of_experience: [],
                salary_pretention: [],
              }
            })
        }
      })
    },
    repeateAgain() {
      this.$refs.infoRules.validate().then(success => {
        if (success) {
          this.forms.push({
            recurring_service_id: '',
            about: '',
            pictures: [],
            years_of_experience: '',
            salary_pretention: '',
          })
        } else {
          reject()
        }
      })
    },
    removeItem(index) {
      this.forms.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
