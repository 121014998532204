<template>
  <div>
    <div
      v-if="isEmployeeInfosLoading"
      class="d-flex justify-content-center mb-1"
      style="padding-top: 10vh"
    >
      <b-spinner
        variant="info"
        style="width: 3rem; height: 3rem"
        class="mr-1"
      />
    </div>
    <div v-else>
      <!-- Media -->
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            ref="previewEl"
            :src="employeeData.profile_image"
            :text="avatarText(employeeData.full_name)"
            size="90px"
            rounded
          />
        </template>
        <h4 class="mb-1">
          {{ employeeData.full_name }}
        </h4>
        <div class="d-flex flex-wrap">
          <b-button
            variant="primary"
            @click="$refs.refInputEl.$el.click()"
          >
            <b-form-file
              ref="refInputEl"
              v-model="profileFile"
              accept=".jpg, .png, .gif, .jpeg"
              :hidden="true"
              plain
              @change="applyChangeEmployeeProfileImageAction"
            />
            <div v-if="isProfileImageLoading">
              <span> Chargement ... </span>
              <b-spinner small />
            </div>
            <span v-else>Modifier la photo</span>
          </b-button>
        </div>
      </b-media>

      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Point Focal"
            >
              <b-form-group
                label="Responsable Point Focal"
                label-for="point-focal"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="point-focal"
                  v-model="employeeData.point_focal_id"
                  :reduce="(item) => item.id"
                  label="name"
                  :options="pointFocalOption"
                  @click="verifydelpointfocal"
                />

                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="services"
              rules="required"
            >
              <b-form-group
                label="L'employé participe au programme YLOMI ?"
                label-for="services"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="is_in_ylomi_program"
                  v-model="employeeData.is_in_ylomi_program"
                  :reduce="(item) => item.value"
                  label="name"
                  :options="[
                    { value: true, name: 'Oui, il participe' },
                    { value: false, name: 'Non, pas encore' },
                  ]"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="employe de prise de gestion"
              rules="required"
            >
              <b-form-group
                label="Est un employé de prise de gestion?"
                label-for="prise-gestion"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="from_customer"
                  v-model="employeeData.from_customer"
                  :reduce="(item) => item.value"
                  label="name"
                  :options="[
                    { value: true, name: 'Oui' },
                    { value: false, name: 'Non' },
                  ]"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Quartier"
              label-for="address"
            >
              <validation-provider
                #default="{ errors }"
                name="lieu"
                rules="required"
              >
                <b-form-input
                  id="address"
                  v-model="employeeData.address"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Entrez l'adresse du pro"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="IFU"
              label-for="ifu"
            >
              <validation-provider
                #default="{ errors }"
                name="ifu"
                rules="length:13"
                autocomplete="off"
              >
                <b-form-input
                  id="ifu"
                  v-model="employeeData.ifu"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Entrez le numéro ifu de l'employé"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Prénom & Nom"
              label-for="full-name"
            >
              <validation-provider
                #default="{ errors }"
                name=" prenom & nom "
                rules="required"
              >
                <b-form-input
                  id="full-name"
                  v-model="employeeData.full_name"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Entrez le prenom suivi du nom du pro"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Numéro de téléphone"
              rules="required"
            >
              <b-form-group
                label="Numéro de téléphone"
                label-for="phone"
              >
                <b-input-group>
                  <b-input-group-prepend
                    is-text
                    :class="errors[0] ? 'border-danger' : ''"
                  >
                    BJ (+229)
                  </b-input-group-prepend>
                  <cleave
                    id="phone"
                    v-model="phone"
                    class="form-control"
                    :class="errors[0] ? 'border-danger' : ''"
                    :raw="false"
                    :options="options.phone"
                  />
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Numéro MTN mobile money"
            >
              <b-form-group
                label="Numéro MTN Mobile Money"
                label-for="mtn_number"
              >
                <b-input-group>
                  <b-input-group-prepend

                    :class="errors[0] ? 'border-danger' : ''"
                  >
                    <v-select
                      v-model="mtnIndice"
                      :options="phoneOptions"
                      label="indice"
                      :reduce="(item) => item.indice"
                    >
                      <template v-slot:no-options>
                        <b-spinner
                          v-if="isGetIndiceLoading"
                          style="width: 2.5rem; height: 2.5rem"
                          class="align-middle text-info"
                        />
                        <b-button
                          v-else
                          variant="primary"
                          @click="applyAddInitialPhoneNumberAction()"
                        >
                          Ajouter
                        </b-button>
                      </template>
                    </v-select>
                  </b-input-group-prepend>
                  <cleave
                    id="mtn_number"
                    v-model="mtn_number"
                    class="form-control"
                    :class="errors[0] ? 'border-danger' : ''"
                    :raw="false"
                    :options="options.mtn_number"
                    placeholder="12 34 56 78"
                  />
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Numéro Moov Flooz"
            >
              <b-form-group
                label="Numéro Moov Flooz"
                label-for="flooz_number"
              >
                <b-input-group :class="errors[0] ? 'border-danger' : ''">
                  <template #prepend>
                    <v-select
                      v-model="floozIndice"
                      :options="phoneOptions"
                      label="indice"
                      :reduce="(item) => item.indice"
                    >
                      <template v-slot:no-options>
                        <b-spinner
                          v-if="isGetIndiceLoading"
                          style="width: 2.5rem; height: 2.5rem"
                          class="align-middle text-info"
                        />
                        <b-button
                          v-else
                          variant="primary"
                          @click="applyAddInitialPhoneNumberAction()"
                        >
                          Ajouter
                        </b-button>
                      </template>
                    </v-select>
                  </template>
                  <cleave
                    id="flooz_number"
                    v-model="flooz_number"
                    class="form-control"
                    :class="errors[0] ? 'border-danger' : ''"
                    :raw="false"
                    :options="options.flooz_number"
                    placeholder="12 34 56 78"
                  />
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Numéro mobile money"
              rules="required"
            >
              <b-form-group
                label="Numéro Mobile Money"
                label-for="phone_mobile_money"
              >
                <b-input-group>
                  <b-input-group-prepend
                    is-text
                    :class="errors[0] ? 'border-danger' : ''"
                  >
                    BJ (+229)
                  </b-input-group-prepend>
                  <cleave
                    id="phone_mobile_money"
                    v-model="mobile_money_number"
                    class="form-control"
                    :class="errors[0] ? 'border-danger' : ''"
                    :raw="false"
                    :options="options.mobile_money_number"
                    placeholder="12 34 56 78"
                  />
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col> -->
          <b-col md="6">
            <b-form-group
              label="Âge"
              label-for="âge"
            >
              <validation-provider
                #default="{ errors }"
                name="âge"
                rules="integer|required"
              >
                <b-form-input
                  id="âge"
                  v-model="employeeData.age"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Entrez l'âge de l'employé"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Situation matrimoniale"
              label-for="marital-status"
            >
              <validation-provider
                #default="{ errors }"
                name="situation matrimoniale"
                rules="required"
              >
                <b-form-input
                  id="marital-status"
                  v-model="employeeData.marital_status"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Entrez la situation matrimoniale de l'employé"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Diplôme / Niveau d'études"
              label-for="degree"
            >
              <validation-provider
                #default="{ errors }"
                name="diplôme / niveau d'études"
                rules="required"
              >
                <b-form-input
                  id="degree"
                  v-model="employeeData.degree"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Entrez le diplôme ou le niveau d'études de l'employé"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nationalité"
              label-for="nationality"
            >
              <validation-provider
                #default="{ errors }"
                name="nationalité"
                rules="required"
              >
                <b-form-input
                  id="nationality"
                  v-model="employeeData.nationality"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Entrez la nationalité de l'employé"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="justificatifs"
            >
              <b-form-group
                label="Justificatifs"
                label-for="proof_files"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-file
                  v-model="employeeData.proof_files"
                  accept=".jpg, .png, .gif, .jpeg"
                  placeholder="Choissez les fichiers justificatifs"
                  multiple
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :disabled="isProProfileInfoLoading"
        @click="applyChangeProProfileInfoAction()"
      >
        <div v-if="isProProfileInfoLoading">
          <span> Chargement ... </span>
          <b-spinner small />
        </div>
        <span v-else>Modifier les infos</span>
      </b-button>
    </div>

    <!-- Make comment Modal -->
    <b-modal
      id="modal-add-phone-number"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Ajouter initial"
    >
      <validation-observer
        #default="{}"
        ref="orderAssignmentForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModalAddIndice()"
        >
          <b-form-group
            label="Entrez l'indice"
            label-for="indice"
          >
            <validation-provider
              #default="{ errors }"
              name="indice"
              rules="required"
            >
              <b-form-input
                id="indice"
                v-model="payloadIndice.indice"
                type="number"

                :state="errors.length > 0 ? false : null"
                placeholder="Entrez l'indice"
                rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Entrez le réseau"
            label-for="indice"
          >
            <validation-provider
              #default="{ errors }"
              name="mob_net"
              rules="required"
            >
              <v-select
                v-model="payloadIndice.mob_net"
                :options="mobNetOptions"
                label="text"
                :reduce="(item) => item.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isAddIndice"
              variant="primary"
              class="mr-2"
              @click="applyAddIndicesAction()"
            >
              <div v-if="isAddIndice">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Soumettre</span>
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
              @click="hideModalAddIndice()"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>

</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BSpinner,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BFormInvalidFeedback,
  // BTable,
  // BCard,
  BFormFile,
  // BCardHeader,
  // BCardTitle,
  // BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import {
  required /* , email , beninPhoneNumberFormatRule */,
} from '@validations'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.bj'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    ValidationObserver,
    ValidationProvider,
    BMedia,
    BFormFile,
    BAvatar,
    BSpinner,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormInvalidFeedback,
    // BTable,
    // BCard,
    // BCardHeader,
    // BCardTitle,
    // BFormCheckbox,
    vSelect,
    Cleave,
  },
  props: {
    employeeData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isGetIndiceLoading: false,
      isProfileImageLoading: false,
      isEmployeeInfosLoading: true,
      isProProfileInfoLoading: false,
      pointFocalOption: [],
      required,
      isAddIndice: false,
      payloadIndice: {
        mob_net: '',
        indice: '',
      },
      mobNetOptions: [
        {
          value: 'MTN',
          text: 'MTN',
        },
        {
          value: 'MOOV',
          text: 'MOOV',
        },
      ],
      // email,
      profileFile: '',
      avatarText,
      phone: '',
      mtn_number: '',
      flooz_number: '',
      point_focal_id: null,
      employee: {
        point_focal_id: null,

      },

      phoneOptions: [],

      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'BJ',
        },
        mtnIndice: '',
        floozIndice: '',
        mtn_number: {
          phone: true,
          phoneRegionCode: 'BJ',
        },
        flooz_number: {
          phone: true,
          phoneRegionCode: 'BJ',
        },
      },

    }
  },

  computed: {
    ...mapGetters('offerType', ['getPointFocaux']),

    phoneNumber() {
      return this.phone.replace(/\s/g, '')
    },

    mtnMobileMoneyNumber() {
      const mtnNum = this.mtnIndice + this.mtn_number
      return mtnNum.replace(/\s/g, '')
    },
    floozMobileMoneyNumber() {
      const mtnNum = this.floozIndice + this.flooz_number
      return this.flooz_number.replace(/\s/g, '')
    },
  },
  watch: {
    getPointFocaux(newVal, oldVal) {
      newVal.data.forEach(element => {
        this.pointFocalOption.push({
          id: element.id,
          name: element.responsable_name,
        })
        this.isEmployeeInfosLoading = false
      })
    },
  },
  async created() {
    this.phone = this.$props.employeeData.phone_number.replace('229', '')
    this.mtnIndice = this.$props.employeeData.mtn_number !== null ? this.$props.employeeData.mtn_number.slice(3, 5) : ''
    this.floozIndice = this.$props.employeeData.flooz_number !== null ? this.$props.employeeData.flooz_number.slice(3, 5) : ''

    const beginNumber = {
      mtnSlice: `229${this.mtnIndice}`,
      floozSlice: `229${this.floozIndice}`,
    }
    console.log(beginNumber, 'beginNumber')
    this.mtn_number = this.$props.employeeData.mtn_number !== null ? this.$props.employeeData.mtn_number.replace(beginNumber.mtnSlice, '') : ''
    this.flooz_number = this.$props.employeeData.flooz_number !== null ? this.$props.employeeData.flooz_number.replace(beginNumber.floozSlice, '') : ''

    this.applyGetIndicesAction()
  },

  mounted() {
    console.log('POINT FOCAL', this.getPointFocaux)
    if (this.getPointFocaux.length != 0) {
      this.getPointFocaux.data.forEach(element => {
        this.pointFocalOption.push({
          id: element.id,
          name: element.responsable_name,
        })
        this.isEmployeeInfosLoading = false
      })
    } else {
      this.applyGetPointFocaux()
    }

    this.configProAddressField()
  },

  methods: {
    ...mapActions('auth', [
      'changeEmployeeProfileImageAction',
      'changeEmployeeProfileInfoAction',
    ]),

    ...mapActions('professionals', ['getEmployeesAction', 'getIndicesAction', 'addIndicesAction']),
    ...mapActions('offerType', ['getPointFocalAction']),

    applyAddInitialPhoneNumberAction() {
      this.$bvModal.show('modal-add-phone-number')
    },

    applyGetIndicesAction() {
      this.isGetIndiceLoading = true
      this.getIndicesAction()
        .then(response => {
          this.isGetIndiceLoading = false
          console.log(response)
          response.data.forEach(element => {
            this.phoneOptions.push({
              id: element.id,
              indice: element.indice,
            })
          })
        })
        .catch(error => {
          this.isGetIndiceLoading = false
          console.log(error)
        })
    },
    hideModalAddIndice() {
      this.$bvModal.hide('modal-add-phone-number')
      this.payloadIndice = {
        indice: '',
      }
    },

    applyAddIndicesAction() {
      this.isAddIndice = true
      console.log(this.payloadIndice)
      this.addIndicesAction(this.payloadIndice)
        .then(response => {
          this.isAddIndice = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Succès',
                icon: 'CheckIcon',
                text: response.message,
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
          this.hideModalAddIndice()
          this.applyGetIndicesAction()
        })
        .catch(error => {
          this.isAddIndice = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
          console.log('Indice Erreur', error)
        })
    },

    applyGetPointFocaux() {
      this.getPointFocalAction()
        .then(response => {
          response.data.data.forEach(element => {
            this.pointFocalOption.push({
              id: element.id,
              name: element.responsable_name,
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
    },

    verifydelpointfocal() {
      const button_del_point_focal = document
        .getElementById('point-focal')
        .document.getElementsByClassName('vs__clear')
      console.log(button_del_point_focal.style.display)
    },

    configProAddressField() {
      const input = document.getElementById('address')
      const options = {
        types: ['establishment'],
        componentRestrictions: { country: ['BJ'] },
      }
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(input, options)

      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace()
        this.$props.employeeData.address = place.name
      })
    },

    applyChangeEmployeeProfileImageAction() {
      // eslint-disable-next-line prefer-destructuring
      this.profileFile = this.$refs.refInputEl.$el.files[0]
      this.url = URL.createObjectURL(this.profileFile)
      this.$props.employeeData.profile_image = this.url
      const payload = {
        id: this.$props.employeeData.id,
        profile_image: this.profileFile,
      }
      this.isProfileImageLoading = true
      this.changeEmployeeProfileImageAction(payload)
        .then(response => {
          // console.log(response.data)
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Succès',
                icon: 'CheckIcon',
                text: 'Photo de profil changée avec succès',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
          this.getEmployeesAction()
          this.isProfileImageLoading = false
        })
        .catch(error => {
          this.isProfileImageLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyChangeProProfileInfoAction() {
   
      if (this.mtnMobileMoneyNumber == '') {
        this.mtnIndice = ''
        /* if (!mtnIndices.includes(this.mtnMobileMoneyNumber.slice(0, 2))) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Attention',
                icon: 'CheckIcon',
                text: 'Numéro MTN MOMO incorrecte',
                variant: 'warning',
              },
            },
            {
              position: 'top-center',
            },
          )
          return
        } */
      }

      /* if(this.floozMobileMoneyNumber !== ""){
        if (!moovIndices.includes(this.floozMobileMoneyNumber.slice(0, 2)) == true) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Attention',
              icon: 'CheckIcon',
              text: 'Numéro FLOOZ  incorrecte',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
        return
      }

    }
     */
      const mtnnum = this.mtnIndice + this.mtn_number
      const flooznum = this.floozIndice + this.flooz_number
      this.isProProfileInfoLoading = true
      const payload = {
        id: this.$props.employeeData.id,
        point_focal_id: this.$props.employeeData.point_focal_id,
        full_name: this.$props.employeeData.full_name,
        address: this.$props.employeeData.address,
        marital_status: this.$props.employeeData.marital_status,
        age: this.$props.employeeData.age,
        from_customer: this.$props.employeeData.from_customer ? 1 : 0,
        degree: this.$props.employeeData.degree,
        ifu: this.$props.employeeData.ifu,
        nationality: this.$props.employeeData.nationality,
        proof_files: this.$props.employeeData.proof_files,
        phone_number: `229${this.phoneNumber}`,
        mtn_number: this.mtn_number == '' ? '' : (`229${(this.mtnIndice + this.mtn_number).replace(/\s/g, '')}`),
        flooz_number: this.flooz_number == '' ? '' : (`229${(this.floozIndice + this.flooz_number).replace(/\s/g, '')}`),
        is_in_ylomi_program: this.$props.employeeData.is_in_ylomi_program ? 1 : 0,
      }
      if (typeof payload.proof_files === 'string') {
        delete payload.proof_files
      }

      this.changeEmployeeProfileInfoAction(payload)
        .then(response => {
          console.log('PAYLOAD', payload)
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Succès',
                icon: 'CheckIcon',
                text: 'Donnée  de profil changée avec succès',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )

          this.getEmployeesAction()
          this.isProProfileInfoLoading = false
          this.$store.commit('professionals/SET_EMPLOYEE_DETAILS', null)
          this.$router.push(`/employees/${response.data.id}/details`)
        })
        .catch(error => {
          this.isProProfileInfoLoading = false
          console.log(error)
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
