var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isEmployeeInfosLoading)?_c('div',{staticClass:"d-flex justify-content-center mb-1",staticStyle:{"padding-top":"10vh"}},[_c('b-spinner',{staticClass:"mr-1",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"info"}})],1):_c('div',[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.employeeData.profile_image,"text":_vm.avatarText(_vm.employeeData.full_name),"size":"90px","rounded":""}})]},proxy:true}])},[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.employeeData.full_name)+" ")]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl.$el.click()}}},[_c('b-form-file',{ref:"refInputEl",attrs:{"accept":".jpg, .png, .gif, .jpeg","hidden":true,"plain":""},on:{"change":_vm.applyChangeEmployeeProfileImageAction},model:{value:(_vm.profileFile),callback:function ($$v) {_vm.profileFile=$$v},expression:"profileFile"}}),(_vm.isProfileImageLoading)?_c('div',[_c('span',[_vm._v(" Chargement ... ")]),_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v("Modifier la photo")])],1)],1)]),_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Point Focal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Responsable Point Focal","label-for":"point-focal","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"point-focal","reduce":function (item) { return item.id; },"label":"name","options":_vm.pointFocalOption},on:{"click":_vm.verifydelpointfocal},model:{value:(_vm.employeeData.point_focal_id),callback:function ($$v) {_vm.$set(_vm.employeeData, "point_focal_id", $$v)},expression:"employeeData.point_focal_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"services","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"L'employé participe au programme YLOMI ?","label-for":"services","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"is_in_ylomi_program","reduce":function (item) { return item.value; },"label":"name","options":[
                  { value: true, name: 'Oui, il participe' },
                  { value: false, name: 'Non, pas encore' } ]},model:{value:(_vm.employeeData.is_in_ylomi_program),callback:function ($$v) {_vm.$set(_vm.employeeData, "is_in_ylomi_program", $$v)},expression:"employeeData.is_in_ylomi_program"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"employe de prise de gestion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Est un employé de prise de gestion?","label-for":"prise-gestion","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"from_customer","reduce":function (item) { return item.value; },"label":"name","options":[
                  { value: true, name: 'Oui' },
                  { value: false, name: 'Non' } ]},model:{value:(_vm.employeeData.from_customer),callback:function ($$v) {_vm.$set(_vm.employeeData, "from_customer", $$v)},expression:"employeeData.from_customer"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Quartier","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"lieu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Entrez l'adresse du pro"},model:{value:(_vm.employeeData.address),callback:function ($$v) {_vm.$set(_vm.employeeData, "address", $$v)},expression:"employeeData.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"IFU","label-for":"ifu"}},[_c('validation-provider',{attrs:{"name":"ifu","rules":"length:13","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ifu","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Entrez le numéro ifu de l'employé"},model:{value:(_vm.employeeData.ifu),callback:function ($$v) {_vm.$set(_vm.employeeData, "ifu", $$v)},expression:"employeeData.ifu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Prénom & Nom","label-for":"full-name"}},[_c('validation-provider',{attrs:{"name":" prenom & nom ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"full-name","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Entrez le prenom suivi du nom du pro"},model:{value:(_vm.employeeData.full_name),callback:function ($$v) {_vm.$set(_vm.employeeData, "full_name", $$v)},expression:"employeeData.full_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Numéro de téléphone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Numéro de téléphone","label-for":"phone"}},[_c('b-input-group',[_c('b-input-group-prepend',{class:errors[0] ? 'border-danger' : '',attrs:{"is-text":""}},[_vm._v(" BJ (+229) ")]),_c('cleave',{staticClass:"form-control",class:errors[0] ? 'border-danger' : '',attrs:{"id":"phone","raw":false,"options":_vm.options.phone},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Numéro MTN mobile money"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Numéro MTN Mobile Money","label-for":"mtn_number"}},[_c('b-input-group',[_c('b-input-group-prepend',{class:errors[0] ? 'border-danger' : ''},[_c('v-select',{attrs:{"options":_vm.phoneOptions,"label":"indice","reduce":function (item) { return item.indice; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [(_vm.isGetIndiceLoading)?_c('b-spinner',{staticClass:"align-middle text-info",staticStyle:{"width":"2.5rem","height":"2.5rem"}}):_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.applyAddInitialPhoneNumberAction()}}},[_vm._v(" Ajouter ")])]},proxy:true}],null,true),model:{value:(_vm.mtnIndice),callback:function ($$v) {_vm.mtnIndice=$$v},expression:"mtnIndice"}})],1),_c('cleave',{staticClass:"form-control",class:errors[0] ? 'border-danger' : '',attrs:{"id":"mtn_number","raw":false,"options":_vm.options.mtn_number,"placeholder":"12 34 56 78"},model:{value:(_vm.mtn_number),callback:function ($$v) {_vm.mtn_number=$$v},expression:"mtn_number"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Numéro Moov Flooz"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Numéro Moov Flooz","label-for":"flooz_number"}},[_c('b-input-group',{class:errors[0] ? 'border-danger' : '',scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-select',{attrs:{"options":_vm.phoneOptions,"label":"indice","reduce":function (item) { return item.indice; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [(_vm.isGetIndiceLoading)?_c('b-spinner',{staticClass:"align-middle text-info",staticStyle:{"width":"2.5rem","height":"2.5rem"}}):_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.applyAddInitialPhoneNumberAction()}}},[_vm._v(" Ajouter ")])]},proxy:true}],null,true),model:{value:(_vm.floozIndice),callback:function ($$v) {_vm.floozIndice=$$v},expression:"floozIndice"}})]},proxy:true}],null,true)},[_c('cleave',{staticClass:"form-control",class:errors[0] ? 'border-danger' : '',attrs:{"id":"flooz_number","raw":false,"options":_vm.options.flooz_number,"placeholder":"12 34 56 78"},model:{value:(_vm.flooz_number),callback:function ($$v) {_vm.flooz_number=$$v},expression:"flooz_number"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Âge","label-for":"âge"}},[_c('validation-provider',{attrs:{"name":"âge","rules":"integer|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"âge","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Entrez l'âge de l'employé"},model:{value:(_vm.employeeData.age),callback:function ($$v) {_vm.$set(_vm.employeeData, "age", $$v)},expression:"employeeData.age"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Situation matrimoniale","label-for":"marital-status"}},[_c('validation-provider',{attrs:{"name":"situation matrimoniale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"marital-status","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Entrez la situation matrimoniale de l'employé"},model:{value:(_vm.employeeData.marital_status),callback:function ($$v) {_vm.$set(_vm.employeeData, "marital_status", $$v)},expression:"employeeData.marital_status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Diplôme / Niveau d'études","label-for":"degree"}},[_c('validation-provider',{attrs:{"name":"diplôme / niveau d'études","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"degree","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Entrez le diplôme ou le niveau d'études de l'employé"},model:{value:(_vm.employeeData.degree),callback:function ($$v) {_vm.$set(_vm.employeeData, "degree", $$v)},expression:"employeeData.degree"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nationalité","label-for":"nationality"}},[_c('validation-provider',{attrs:{"name":"nationalité","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nationality","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Entrez la nationalité de l'employé"},model:{value:(_vm.employeeData.nationality),callback:function ($$v) {_vm.$set(_vm.employeeData, "nationality", $$v)},expression:"employeeData.nationality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"justificatifs"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Justificatifs","label-for":"proof_files","state":errors.length > 0 ? false : null}},[_c('b-form-file',{attrs:{"accept":".jpg, .png, .gif, .jpeg","placeholder":"Choissez les fichiers justificatifs","multiple":""},model:{value:(_vm.employeeData.proof_files),callback:function ($$v) {_vm.$set(_vm.employeeData, "proof_files", $$v)},expression:"employeeData.proof_files"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","disabled":_vm.isProProfileInfoLoading},on:{"click":function($event){return _vm.applyChangeProProfileInfoAction()}}},[(_vm.isProProfileInfoLoading)?_c('div',[_c('span',[_vm._v(" Chargement ... ")]),_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v("Modifier les infos")])])],1),_c('b-modal',{attrs:{"id":"modal-add-phone-number","cancel-variant":"outline-secondary","hide-footer":"","centered":"","no-close-on-backdrop":"","no-close-on-esc":"","title":"Ajouter initial"}},[_c('validation-observer',{ref:"orderAssignmentForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-form',{staticClass:"p-2",attrs:{"autocomplete":"off"},on:{"reset":function($event){$event.preventDefault();return _vm.hideModalAddIndice()}}},[_c('b-form-group',{attrs:{"label":"Entrez l'indice","label-for":"indice"}},[_c('validation-provider',{attrs:{"name":"indice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"indice","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Entrez l'indice","rows":"3"},model:{value:(_vm.payloadIndice.indice),callback:function ($$v) {_vm.$set(_vm.payloadIndice, "indice", $$v)},expression:"payloadIndice.indice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Entrez le réseau","label-for":"indice"}},[_c('validation-provider',{attrs:{"name":"mob_net","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.mobNetOptions,"label":"text","reduce":function (item) { return item.value; }},model:{value:(_vm.payloadIndice.mob_net),callback:function ($$v) {_vm.$set(_vm.payloadIndice, "mob_net", $$v)},expression:"payloadIndice.mob_net"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"disabled":_vm.isAddIndice,"variant":"primary"},on:{"click":function($event){return _vm.applyAddIndicesAction()}}},[(_vm.isAddIndice)?_c('div',[_c('span',[_vm._v(" Chargement ... ")]),_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v("Soumettre")])]),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function($event){return _vm.hideModalAddIndice()}}},[_vm._v(" Annuler ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }